import React, { useEffect } from 'react';
import luxeLogo from './images/luxe360_logo_white.png';
import backgroundImg from './images/la-background.png';
import mobileIcon from './images/mobile.svg';
import mailIcon from './images/mail02.svg';
import instagramIcon from './images/instagram.svg';
import tiktokIcon from './images/tiktok.svg';
import photo1 from './images/photo0.jpg';
import photo2 from './images/photo2.jpeg';
import photo3 from './images/photo3.jpeg';
import vip1 from './images/vip.jpeg';
import vip2 from './images/vip3.jpg';
import vip3 from './images/vip2.jpg';
import promoVideo from './videos/20240615_203507984.MP4';
import './App.css';

function App() {
  useEffect(() => {
    const alertShown = localStorage.getItem('inquiryConfirmedAlertShown');
    const params = new URLSearchParams(window.location.search);

    if (params.get('inquiryConfirmed') === 'true' && !alertShown) {
      alert('Inquiry Confirmed! We will be in touch soon!');
      localStorage.setItem('inquiryConfirmedAlertShown', 'true');
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="background-container">
          <img src={luxeLogo} alt="Luxe 360 LA Logo in white" className="luxe-logo" />
        </div>
      </header>

      {/* Video Section */}
      <section className="video-section">
        <video
          className="promo-video"
          src={promoVideo}
          autoPlay
          loop
          muted
          playsInline
          aria-label="Luxe 360 LA Promo Video"
        ></video>
      </section>

      {/* Package Section */}
      <section className="container service-section">
        <p className="text-center low-margin logo-subheader-font">Capture the Moments with the</p>
        <h1 className="text-center logo-font">Luxe 360 Video Booth</h1>
        <p className="text-center">
          Luxe 360 LA brings a high-end photo experience to your events, capturing stunning moments with style.
          <br />
          Check out some of our featured packages below!
        </p>

        <div className="row">
          {/* Package 1 */}
          <article className="col-md-4 d-flex align-items-stretch">
            <div className="card h-100 w-100">
              <div className="card-body d-flex flex-column">
                <h2 className="card-title">Basic Package</h2>
                <ul className="list-unstyled flex-grow-1">
                  <li>360 Video Booth</li>
                  <li>In-Person Attendant</li>
                  <li>Custom Overlay</li>
                  <li>Props</li>
                  <li>Delivery, Setup, and Breakdown</li>
                </ul>
              </div>
              <img src={photo1} alt="Luxe 360 Video Booth at Wedding Event" className="card-img-bottom package-img" />
              <div className="card-footer text-right">
                <span className="original-price">$550</span>
                <span className="promo-price">$400</span>
              </div>
            </div>
          </article>

          {/* Package 2 */}
          <article className="col-md-4 d-flex align-items-stretch">
            <div className="card h-100 w-100">
              <div className="card-body d-flex flex-column">
                <h2 className="card-title">Deluxe Package</h2>
                <ul className="list-unstyled flex-grow-1">
                  <li>Package 1 Features</li>
                  <li>9' x 10' Backdrop</li>
                  <li>Backdrop upgrades available</li>
                </ul>
              </div>
              <img src={photo2} alt="Deluxe Luxe 360 Booth Setup with Backdrop" className="card-img-bottom package-img" />
              <div className="card-footer text-right">
                <span className="original-price">$650</span>
                <span className="promo-price">$500</span>
              </div>
            </div>
          </article>

          {/* Package 3 */}
          <article className="col-md-4 d-flex align-items-stretch">
            <div className="card h-100 w-100">
              <div className="card-body d-flex flex-column">
                <h2 className="card-title">Premium Package</h2>
                <ul className="list-unstyled flex-grow-1">
                  <li>Package 1 Features</li>
                  <li>10' x 10' Inflatable LED Enclosure</li>
                </ul>
              </div>
              <img src={photo3} alt="Luxe 360 Booth with Inflatable LED Enclosure" className="card-img-bottom package-img" />
              <div className="card-footer text-right">
                <span className="original-price">$850</span>
                <span className="promo-price">$600</span>
              </div>
            </div>
          </article>
        </div>

        <article className="card vip-package mt-4">
          <div className="card-body">
            <h2 className="card-title text-center">VIP Package</h2>
            <ul className="list-unstyled">
              <li className="text-center">Premium Package Features</li>
              <li className="text-center">Side Table with Custom Signage</li>
              <li className="text-center">Red Carpet Entrance</li>
              <li className="text-center">20" TV with Video Gallery</li>
            </ul>
          </div>
          <div className="vip-images">
            <img src={vip1} alt="VIP Package Luxe Event 1" className="package-img" />
            <img src={vip2} alt="VIP Package Luxe Event 2" className="package-img" />
            <img src={vip3} alt="VIP Package Luxe Event 3" className="package-img" />
          </div>
          <div className="card-footer text-right">
            <span className="original-price">$1050</span>
            <span className="promo-price">$875</span>
          </div>
        </article>

        <p className='text-center'>
          All Packages: 
          <span className="original-price">$150</span>
          <span className="promo-price">$100</span> / Additional hour
        </p>

        <div className="text-center mt-4">
          <a href="https://cal.com/luxe360la" target="_blank" rel="noopener noreferrer" className="availability-button">
            Check Luxe 360 Video Booth Availability
          </a>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="content-below">
        <h2>CONNECT WITH US</h2>
        <div className="social-icons logo-subheader-font">
          <a href="https://www.instagram.com/luxe360la" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" className="icon" />
          </a>
          <a href="https://www.tiktok.com/@luxe360la" target="_blank" rel="noopener noreferrer">
            <img src={tiktokIcon} alt="TikTok" className="icon" />
          </a>
          <a href="tel:+18185276297" target="_blank" rel="noopener noreferrer">
            <img src={mobileIcon} alt="Phone" className="icon" />
          </a>
          <a href="mailto:hello@luxe360la.com" target="_blank" rel="noopener noreferrer">
            <img src={mailIcon} alt="Email" className="icon" />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
